import { Container, TextField, Typography, Button } from "@mui/material";
import { useState } from "react";
import { getDeviceId } from '../../storage/AppData';
import { doLogin } from '../../api/LoginClient';
import { useNavigate } from "react-router-dom";
import { saveUserDetail } from '../../storage/UserData'
import { saveTokenDetail } from '../../storage/TokenData'


export function VerifyOtp(props) {
    const [isError, setIsError] = useState(false);
    const [errMsg, setErrorMsg] = useState('');
    const [otp, setOtp] = useState();
    const [disableButton, setDisableButton] = useState(true);


    const onOtpChange = (e) => {
        const otp = e.target.value;
        setDisableButton(otp.length != 4);
        setOtp(otp);

    }
    const verifyOtp = () => {
        console.log(props);
        const body = {
            mobileNo: props.payload.mobileNo,
            deviceId: getDeviceId(),
            userType: "ADMIN",
            lsOtp: props.payload.lsOtp,
            smsOtp: otp
        };
        doLogin(body, onLoginSuccess, onLoginFailed);
    }
    const onLoginSuccess = (payload) => {
        saveUserDetail(payload.user);
        saveTokenDetail(payload.tokens);
        props.onLoginDone(payload);
    }
    const onLoginFailed = (error) => {
        setErrorMsg(error.message);
        setIsError(true);
    }

    return (
        <Container align="center" sx={{ mt: 32 }} maxWidth="sm">
            <Typography gutterBottom variant="h4" align="center">
                Verify OTP
            </Typography>
            <br />
            <Typography gutterBottom variant="body1">
                An OTP has been sent to your Mobile Number
            </Typography>
            <br />
            <TextField label="OTP" placeholder="Enter OTP" onChange={onOtpChange} variant="outlined" required
                helperText={errMsg} error={isError} />
            <br />
            <br />
            <Button variant="contained" onClick={verifyOtp} disabled={disableButton}>Verify OTP</Button>
        </Container>
    );
}