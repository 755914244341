import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Button, CardHeader, Container, Grid } from '@mui/material';
import NBImage from '../image/NBImage';
import { getFullAddress } from '../../utils/Util';
import Dropdown from '../dropdown/Dropdown';
import { MerchantDetailStatusValues } from '../../constants/Constants';
import InputAlertDialog from '../alert/InputAlertDialog';
import { useState } from 'react';

export default function ShopDetail(props) {

    const shopDetail = props.shopDetail ? props.shopDetail : {}
    const [statusChanged, setStatusChanged] = useState(false);
    const [status, setStatus] = useState();
    const [showDialog, setShowDialog] = useState(false);

    const updateShopStatus = (feedback) => {
        props.updateShopStatus({ status: status, statusDesc: feedback })
    }
    const onStatusChange = (status) => {
        setStatus(status);
        setStatusChanged(true);
    }

    return (
        <Card sx={{
            minWidth: 150
        }}>
            <CardHeader
                title="Shop Details"
            />
            <Grid xs={6} item>
                <Box
                    sx={{
                        display: 'flex',
                        padding: 2
                    }}>

                    <NBImage title="Shop Image" src={shopDetail.imageUrl} />

                    <Box
                        align='left'
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            ml: 3,
                        }}>
                        <Typography >Shop Name- </Typography>
                        <Typography sx={{ mt: 1 }}>Address -</Typography>
                        <Typography sx={{ mt: 1 }}>Opening Time - </Typography>
                        <Typography sx={{ mt: 1 }}>Closing Time -</Typography>
                        <Typography sx={{ mt: 1 }}>Current Status -</Typography>
                        <Typography sx={{ mt: 6 }}>Status Description -</Typography>


                    </Box>
                    <Box
                        align='left'
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            ml: 3,
                        }}>
                        <Typography >{shopDetail.name}</Typography>
                        <Typography sx={{ mt: 1 }}>{getFullAddress(props.address)}</Typography>
                        <Typography sx={{ mt: 1 }}>{shopDetail.openingTime?.timeText} </Typography>
                        <Typography sx={{ mt: 1 }}>{shopDetail.closingTime?.timeText}</Typography>
                        <Dropdown mt={3} items={MerchantDetailStatusValues} selectedItem={shopDetail?.status}
                            onSelectItem={(status) => { onStatusChange(status) }} />
                        <Typography sx={{ mt: 2 }}>{shopDetail?.description}</Typography>

                    </Box>
                </Box>
            </Grid>
            <Grid align="end" sx={{ margin: 2 }} item>
                <Button variant='contained' onClick={() => setShowDialog(true)} disabled={!statusChanged}>Update</Button>
            </Grid>
            <InputAlertDialog open={showDialog} onDialogClose={() => { setShowDialog(false) }}
                onPositiveClick={(message) => {
                    setShowDialog(false);
                    updateShopStatus(message);
                }} title={"Do you want to update Shop status to " + status + "?"}
                inputLabel="Status Descritpion" inputPlaceHolder="Enter the Description" />

        </Card>
    );
}