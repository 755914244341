import { getGuid } from '../storage/UserData'
import { accessToken } from '../storage/TokenData';
import { clearAppData } from '../storage/AppData';
import axios from "axios";


const CONTENT_TYPE_JSON = 'application/json';
const CONTENT_TYPE_FORM_DATA = 'multipart/form-data';
const BEARER = 'Bearer ';
//const BASE_URL = 'https://nbapi.qa.nearbyu.in'; //qa
//const BASE_URL = 'http://localhost:8080'; //dev
const BASE_URL = 'https://nbapi.prod.nearbyu.in'; //prod


export const fetchData = (props, onSuccess, onFailed) => {
    return axios.get(BASE_URL + props.path, {
        headers: {
            'Content-Type': CONTENT_TYPE_JSON,
            'X-GUID': getGuid(),
            Authorization: BEARER + accessToken()
        },
        params: props.params
    })
        .then((response) => {
            onSuccess(response.data.payload)
        })
        .catch((error) => {
            parseFailure(error, onFailed);
        })
};

export const postData = (props, onSuccess, onFailed) => {
    return axios.post(BASE_URL + props.path,
        props.body, {
        headers: {
            'Content-Type': CONTENT_TYPE_JSON,
            'X-GUID': getGuid(),
            'Access-Control-Allow-Origin': '*',
            Authorization: BEARER + accessToken()
        }
    })
        .then((response) => {
            onSuccess(response.data.payload);
        })
        .catch((error) => {
            parseFailure(error, onFailed);
        })
};

export const postMultiPartData = (props, onSuccess, onFailed) => {
    return axios.post(BASE_URL + props.path, props.body, {
        headers: {
            'Content-Type': CONTENT_TYPE_FORM_DATA,
            'X-GUID': getGuid(),
            'Access-Control-Allow-Origin': '*',
            Authorization: BEARER + accessToken()
        }
    })
        .then((response) => {
            onSuccess(response.data.payload);
        })
        .catch((error) => {
            parseFailure(error, onFailed);
        })
};


export const updateMultiPartData = (props, onSuccess, onFailed) => {
    return axios.put(BASE_URL + props.path, props.body, {
        headers: {
            'Content-Type': CONTENT_TYPE_FORM_DATA,
            'X-GUID': getGuid(),
            'Access-Control-Allow-Origin': '*',
            Authorization: BEARER + accessToken()
        }
    })
        .then((response) => {
            onSuccess(response.data.payload);
        })
        .catch((error) => {
            parseFailure(error, onFailed);
        })
};

export const updateData = (props, onSuccess, onFailed) => {
    return axios.put(BASE_URL + props.path,
        props.body, {
        headers: {
            'Content-Type': CONTENT_TYPE_JSON,
            'X-GUID': getGuid(),
            'Access-Control-Allow-Origin': '*',
            Authorization: BEARER + accessToken()
        }
    })
        .then((response) => {
            onSuccess(response.data.payload);
        })
        .catch((error) => {
            parseFailure(error, onFailed);
        })
};

const parseFailure = (error, onFailed) => {
    if (error.response) {
        console.log("API Error Occurred");
        if (error.response.status === 401) {
            clearAppData();
            window.location = window.location.protocol + "//" + window.location.host + "/login"
        }
        else onFailed(error.response.data);
    }
}

//Async api methods

/**
 * This method will be called using await to get data from server and not need to pass callback methods
 * @param {*} props 
 * @returns 
 */

export const getData = async (props) => {
    try {
        const response = await axios.get(BASE_URL + props.path, {
            headers: {
                'Content-Type': CONTENT_TYPE_JSON,
                'X-GUID': getGuid(),
                Authorization: BEARER + accessToken()
            },
            params: props.params
        })
        return { payload: response.data?.payload, error: null }
    }
    catch (error) {
        return { payload: null, error: parseErrorWithAsync(error) }
    }
}

export const postDataAsync = async (props) => {
    try {
        const response = await axios.post(BASE_URL + props.path,
            props.body, {
            headers: {
                'Content-Type': CONTENT_TYPE_JSON,
                'X-GUID': getGuid(),
                'Access-Control-Allow-Origin': '*',
                Authorization: BEARER + accessToken()
            }
        });
        return { payload: response.data?.payload, error: null }
    } catch (error) {
        return { payload: null, error: parseErrorWithAsync(error) }
    }
};

const parseErrorWithAsync = (error) => {
    if (error.response) {
        console.log("API Error Occurred");
        if (error.response.status === 401) {
            clearAppData();
            window.location = window.location.protocol + "//" + window.location.host + "/login"
        }
        return error.response?.data;
    }
    return { message: "Something went wrong" }
}

