import { makeStyles } from "@material-ui/core";

export const nbContainerStyles = makeStyles({
  listContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24
  },
  listHeaderContainer: {
    paddingLeft: 24,
    paddingBottom: 24
  }
},
);
export const nbListStyles = makeStyles({
  grideContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24
  },
  topContainer: {
    paddingLeft: 24,
    paddingBottom: 24
  }
}
);