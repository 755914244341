import { Button, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';

export default function UserItem({ user }) {
    const name = (user.profile?.firstName == null || user.profile?.firstName.length == 0) ? user.mobileNo : (user.profile?.firstName + user.profile?.lastName)
    return (

        <Card sx={{ minWidth: 150 }} elevation={2}>
            <CardHeader
                titleTypographyProps={{ variant: 'h6' }}
                title={name}
                subheader={user.status}
                align="start"
            />
            <CardActionArea>
                <CardMedia
                    component="img"
                    width={200}
                    image={user.profile?.imageUrl}
                    sx={{ maxHeight: "200px" }} />
            </CardActionArea>
            <CardContent>
                <Typography sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3
                }} variant="body2" color="text.secondary" align='left'>
                    {user.statusDesc}
                </Typography>
            </CardContent>
            <CardActions>
                <Grid xs={12} align="right" item >
                    <Link className='btn' to={'/userDetails/'}>
                        <Button variant="contained" color="primary">detail</Button>
                    </Link>
                </Grid>

            </CardActions>
        </Card>
    )
}