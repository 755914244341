import React from 'react'
import { Container, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material'
import MainDrawer from '../../components/MainNavbar'
import Loading from '../../components/nb-loading/Loading'
import { nbContainerStyles } from '../../style/NbMuiStyles'
import { useState, useEffect } from 'react'
import UserGridList from './UserGridList'
import { UserStatusValues } from '../../constants/UserConstants'
import { fetchUserListByStatus } from '../../api/UserApi'
import ErrorAlert from '../../components/alert/ErrorAlert'
import Dropdown from '../../components/dropdown/Dropdown'
import NbPagination from '../../components/pagination/NbPagination'
import { GetUserCountUseCase } from '../../domain/usecase/user/GetUserCountUseCase'


const UserList = () => {
    const classes = nbContainerStyles();
    const [loading, setLoading] = useState(false);
    const [userItems, setUserItems] = useState([]);
    const [status, setStatus] = useState(UserStatusValues[0]);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPage, setTotalPage] = useState(1);
    const [countPayload, setCountPayload] = useState([])


    const fetchUserList = (status, pageNo) => {
        fetchUserListByStatus({ pageNo: pageNo, pageSize: pageSize, status: status }, onUserListSuccess, onUserListFailed);
    }
    const onUserListSuccess = (items) => {
        setLoading(false)
        setUserItems(items);
    }
    const onUserListFailed = (error) => {
        setLoading(false)
        console.error(error.message);
    }
    const onStatusChange = (status) => {
        setStatus(status);
        updatePagination(countPayload, status)
        fetchUserList(status, pageNo);
    }

    const onPageSelect = (page) => {
        setPageNo(page)
        fetchUserList(status, page)
    }
    const fetchUserCounts = async () => {
        const { payload, error } = await GetUserCountUseCase()
        setCountPayload(payload)
        updatePagination(payload, status)
    }

    const updatePagination = (payload, status) => {
        setTotalPage(1)
        if (payload != null) {
            payload.map(item => {
                if (status == item.status) {
                    var count = Math.floor(item.count / pageSize) + ((item.count % pageSize == 0) ? 0 : 1)
                    setTotalPage(count)
                }
            })
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchUserList(status, 1);
        fetchUserCounts()
    }, []);
    return (
        <>
            <Container>
                <MainDrawer />
                <ErrorAlert />
                <Container>
                    <Grid>
                        <Grid container className={classes.listHeaderContainer}>
                            <Grid xs={4} align="start" item>
                                <Typography variant='h4'>UserList</Typography>
                            </Grid>
                            <Grid xs={8} align="right" item>
                                <Grid container alignSelf="right">
                                    <Typography sx={{ maxLines: 2, lineClamp: 2 }} variant='h6' align='right'>
                                        Select Status
                                    </Typography>
                                    <Dropdown items={UserStatusValues} selectedItem={status}
                                        onSelectItem={(status) => onStatusChange(status)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Loading loading={loading} mt={12} />
                        <Grid className={classes.listContainer}>
                            <UserGridList userItems={userItems} />
                        </Grid>
                        <Grid xs={12} align="center" item mt={8}>
                            <NbPagination totalPage={totalPage} onPageSelect={(page) => onPageSelect(page)} />
                        </Grid>
                    </Grid>
                </Container>
            </Container>
        </>
    )
}

export default UserList;
