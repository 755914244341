import React, { useState, useEffect } from 'react';
import { Button, Grid, Container, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import "../../style/style.css";
import CategoryItem from '../../components/category/CategoryItem';
import { makeStyles } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import { fetchCategoryList } from '../../api/CategoryApi'
import MainNavbar from "../../components/MainNavbar";
import Loading from '../../components/nb-loading/Loading';
import SessionAlert from '../login/session/SessionAlert';

const useStyles = makeStyles({
  grideContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24
  },
  topContainer: {
    paddingLeft: 24,
    paddingBottom: 24
  }
}
);

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const onItemClick = (item) => {
    //TODO: navigate to category detail 
  }
  const classes = useStyles();
  const navigate = useNavigate();


  useEffect(() => {
    setLoading(true);
    fetchCategoryList(onCategoryListSuccess, onCategoryListFailed);
  }, [])

  const onCategoryListSuccess = (payload) => {
    setLoading(false);
    setCategories(payload)
  }
  const onCategoryListFailed = (error) => {
    setLoading(false);
    alert(error.message);
  }
  const onUnthorized = (error) => {
    navigateToLogin();
  }

  const navigateToLogin = () => {
    navigate('/login', { replace: true });
  }

  return (
    <Container>
      <MainNavbar />
      <Container>
        <Grid container className={classes.topContainer} alignContent="baseline">
          <Grid xs={8} align="start" item >
            <Typography variant="h4">Category List</Typography>
          </Grid>
          <Grid xs={4} align="end" item >
            <Link className='btn' to={'/addCategory'}>
              <Button variant="contained" color="primary" onClick={loading}>Add Category </Button>
            </Link>
          </Grid>

        </Grid>
        <Loading loading={loading} mt={16} />
        <Grid container spacing={3} className={classes.grideContainer} >
          {
            categories.map(category =>
            (<Grid key={category.id} xs={12} sm={6} md={3} item>
              < CategoryItem category={category} />
            </Grid>))
          }
        </Grid>
      </Container>

    </Container>
  );
}

export default CategoryList