import { postData, postDataAsync } from "./ApiClient";
const LOGIN_PATH = "/auth"

export const requestOtp = (body, onOtpSentSuccess, onOtpSentFailed) => {
    return postData({ path: LOGIN_PATH + '/request-otp', body: body }, onOtpSentSuccess, onOtpSentFailed);
}

export const doLogin = (body, onLoginSuccess, onLoginFailed) => {
    return postData({ path: LOGIN_PATH + '/sign-in', body: body }, onLoginSuccess, onLoginFailed);
}

export const getNewToken = async (body) => {
    return await postDataAsync({ path: LOGIN_PATH + "/token", body: body });
}