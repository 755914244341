import { useState, useEffect } from 'react';
import { Button, Card, CardContent, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { blobFromJson } from '../../utils/Util';
import { saveProduct, updateProduct } from '../../api/ProductApi';
import Box from '@mui/system/Box';
import { fetchUnitValues } from '../../api/BucketApi';

export default function ProductDetail(props) {
    const productDetail = props.productDetail
    const [file, setFile] = useState({});
    const [fileUri, setFileUri] = useState(productDetail?.imageUrl);
    const [proName, setProName] = useState(productDetail?.name);
    const [proCode, setProCode] = useState(productDetail?.code);
    const [proDes, setProDes] = useState(productDetail?.description);
    const [proBenifit, setProBenifit] = useState(productDetail?.benefits);
    const [proUse, setProUse] = useState(productDetail?.storageAndUsage);
    const [isProActive, setIsProActive] = useState(productDetail?.active);
    const [selectedUnit, setSelectedUnit] = useState(productDetail?.unitBucket);
    const [unitValues, setUnitValues] = useState([]);
    const [disableButton, setDisableButton] = useState(true)
    const navigate = useNavigate();

    const onSaveProductClick = () => {
        const formData = new FormData();
        formData.append("productImage", file);
        formData.append('product', blobFromJson({
            "id": productDetail?.id,
            "name": proName,
            "code": proCode,
            "description": proDes,
            "benefits": proBenifit,
            "storageAndUsage": proUse,
            "active": isProActive,
            "unitBucket": selectedUnit
        }));
        updateProduct(formData, onProductSavedSuccess, onProductSaveFailed);
    }
    const onProductSavedSuccess = (product) => {
        navigateToProductList();
        props.onProductSave();
        console.log(product.id);
    }
    const onProductSaveFailed = (error) => {
        //alert(error.message);
        console.log(error.message);
    }
    const onUnitSelect = (e) => {
        setSelectedUnit(e.target.value);
        setDisableButton(false)
    }


    const selectFile = (event) => {
        setFile(event.target.files[0]);
        setFileUri(URL.createObjectURL(event.target.files[0]));
        setDisableButton(false);
    }
    const navigateToProductList = () => {
        navigate('/productList', { replace: true });
    };
    const onBucketListSuccess = (bucketPayload) => {
        bucketPayload.map(item => { if (item.id == productDetail?.unitBucket?.id) setSelectedUnit(item) });
        setUnitValues(bucketPayload);
    }
    const onBucketListFailed = (e) => {
        console.log(e.message);
    }
    useEffect(() => {
        fetchUnitValues(onBucketListSuccess, onBucketListFailed);
        console.log("unit", selectedUnit);
    }, []);
    return (
        <div className='product'>
            <Box sx={{
                border: '1px black'
            }}>
                <Card style={{ maxWidth: 600, margin: "0 auto", padding: "25px 25px", marginBottom: 32 }}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid xs={12} align='center' item >
                                <Grid>
                                    <img className="preview-img"
                                        src={fileUri}
                                    />
                                </Grid>
                                <Button variant="contained" component="label">
                                    Upload File
                                    <input
                                        align="center"
                                        type="file"
                                        hidden
                                        onChange={selectFile}
                                    />
                                </Button>
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Name" value={proName} onChange={e => { setProName(e.target.value); setDisableButton(false) }} placeholder="Product Name" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Code" disabled={true} value={proCode} onChange={e => setProCode(e.target.value)} placeholder="Enter Code" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Description" value={proDes} multiline rows={3} onChange={e => { setProDes(e.target.value); setDisableButton(false) }} placeholder="Enter Description" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Benifit" value={proBenifit} multiline rows={3} onChange={e => { setProBenifit(e.target.value); setDisableButton(false) }} placeholder="Enter Benifit" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Store&Usage" value={proUse} multiline rows={3} onChange={e => { setProUse(e.target.value); setDisableButton(false) }} placeholder="Enter Store & Usage" variant="outlined" fullWidth required />
                            </Grid>

                            <Grid xs={8} item />
                            <Grid xs={12} align="left" item>
                                <FormControl sx={{ minWidth: 300 }}>
                                    <InputLabel id="unit-select-label">Unit Bucket</InputLabel>
                                    <Select
                                        labelId="unit-select-label"
                                        id="unit-select"
                                        value={selectedUnit}
                                        label="Unit Bucket"
                                        onChange={onUnitSelect}
                                    >
                                        {unitValues.map(unitValue =>
                                            <MenuItem value={unitValue} key={unitValue.id}>{unitValue.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} align="left" item>
                                <FormLabel id="demo-row-radio-buttons-group-label" row>Active</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={e => {
                                        setIsProActive(e.target.value); setDisableButton(false)
                                    }}
                                    value={isProActive}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />

                                </RadioGroup>
                            </Grid>
                            <Grid xs={12} align="right" item >
                                <Button type="save" variant="contained" color="primary" disabled={disableButton} onClick={onSaveProductClick}>Update</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </div>
    )
}
