import { Container } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { RequestOtp } from '../../components/login/RequestOtp';
import { VerifyOtp } from '../../components/login/VerifyOtp';

function Login() {

  const [otpSent, setOtpSent] = useState(false);
  const [payload, setPayload] = useState({});
  const navigate = useNavigate();

  const onOtpSentSuccess = (payload) => {
    setPayload(payload);
    setOtpSent(true);
  }

  const onLoginSuccess = (payload) => {
    console.log(payload);
    navigate('/dashboard', { replace: true });
  }

  return (
    <Container maxWidth="sm" sx={{ mb: 4 }} >

      {!otpSent ?
        (<RequestOtp onOtpSent={payload => onOtpSentSuccess(payload)} />) :
        (<VerifyOtp payload={payload} onLoginDone={payload => onLoginSuccess(payload)} />)}

    </Container>
  );
}


export default Login;
