import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid, Button } from '@mui/material';
import { useState } from 'react';
import CategoryDetailDailog from '../categoryDetail/CategoryDetailDailog';

export default function CategoryItem(props) {
  const [open, setOpen] = useState()
  const category = props.category
  const childern = props.childern
  return (
    <>
      <Card sx={{ minWidth: 150 }} elevation={2}>
        <CardHeader
          titleTypographyProps={{ variant: 'h6' }}
          title={category.name}
        />
        <CardActionArea>
          <CardMedia
            component="img"
            width={200}
            maxHeight={200}
            image={category.imageUrl}
          />
        </CardActionArea>
        <CardContent>
          <Typography sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3
          }} variant="body2" color="text.secondary" align='justify'>
            {category.description}
          </Typography>
        </CardContent>
        <CardContent>
          <Grid xs={12} item align="end">
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}> Detail</Button>
          </Grid>
        </CardContent>
      </Card>
      <CategoryDetailDailog open={open} categoryDetail={category} setOpen={() => { setOpen(false) }}>
      </CategoryDetailDailog>
    </>
  );
}
