import { saveLastLoginTime } from "./AppData";

const TOKEN_DETAIL_KEY = 'token_detail'

export const saveTokenDetail = (tokenDetails) => {
    saveLastLoginTime();
    return localStorage.setItem(TOKEN_DETAIL_KEY, JSON.stringify(tokenDetails));
}

export const getTokenDetail = () => {
    return JSON.parse(localStorage.getItem(TOKEN_DETAIL_KEY))
}

export const accessToken = () => {
    const token = getTokenDetail()
    return token == null ? '' : token.accessToken
}

export const refreshToken = async () => {
    const token = await getTokenDetail()
    return token == null ? '' : token.refreshToken
}