import { Container, Typography, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { getDeviceId } from '../../storage/AppData';
import { requestOtp } from '../../api/LoginClient';



export function RequestOtp(props) {
    const [isError, setIsError] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [mobileNumber, setMobileNumber] = useState();
    const [errMsg, setErrorMsg] = useState('');

    const onMobleNoChange = (e) => {
        setMobileNumber(e.target.value);
        setDisableButton(e.target.value.length != 10);
    }
    const sendOtp = () => {
        requestOtp({
            mobileNo: mobileNumber,
            deviceId: getDeviceId()
        }, onOtpSentSuccess, onOtpSentFailed);
    }
    const onOtpSentSuccess = (payload) => {
        props.onOtpSent({ lsOtp: payload.lsOtp, mobileNo: mobileNumber });
    }
    const onOtpSentFailed = (error) => {
        setIsError(true);
        setErrorMsg(error.message);
    }

    return (
        <Container align="center" sx={{ mt: 32 }} maxWidth="sm">
            <Typography gutterBottom variant="h4" align="center">
                Login to Nearby
            </Typography>
            <br />
            <TextField label="Mobile number" id="outlined-error-helper-text" placeholder="Enter Mobile Number" onChange={onMobleNoChange} variant="outlined" required
                helperText={errMsg} error={isError} />
            <br />
            <br />
            <Button type="Save" variant="contained" onClick={sendOtp} disabled={disableButton}>Send OTP</Button>
        </Container>
    );
} 