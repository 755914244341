import { Container } from 'postcss';
import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Grid } from '@mui/material';

export default function Loading(props) {
    const marginTop = props.mt ? props.mt : 0
    return (
        <>
            {props.loading ?
                <Box align='center' sx={{
                    width: "100%",
                    mt: marginTop

                }}>
                    <CircularProgress />
                </Box>
                : null}
        </>
    );
}

