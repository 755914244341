import { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function GroupSelection(props) {
    const [selectedGroup, setSelectedGroup] = useState();
    const onGroupSelect = (e) => {
        const value = e.target.value
        props.onGroupSelect(value);
        setSelectedGroup(value);
    }
    return (
        <FormControl sx={{ minWidth: 150 }} >
            <InputLabel id="group-select-label">Group </InputLabel>
            <Select
                labelId="group-select-label"
                id="grop-select"
                value={selectedGroup}
                label="Group"
                onChange={onGroupSelect}
            >
                {
                    props.groupItems.map(group => (
                        <MenuItem value={group}>{group.name}</MenuItem>))
                }
            </Select>
        </FormControl>
    )
}