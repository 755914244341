import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import GroupDetail from './GroupDetail'

export default function GroupDetailDialog(props) {
    const { groupDetail } = props;

    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        < >
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div>

                    <DialogTitle>
                        <div style={{ display: 'flex' }} >
                            <div style={{ flexGrow: 1 }}>{groupDetail?.name}</div>
                            <Button
                                onClick={handleClose}
                                color="error"
                                variant='contained' >
                                X</Button>
                        </div>
                    </DialogTitle>

                    <DialogContent>
                        <GroupDetail groupDetail={groupDetail} onGroupUpadated={() => { props.setOpen(false) }} />
                    </DialogContent>
                </div>
            </Dialog>
        </>
    );
}