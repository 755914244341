import { useEffect, useState } from "react";
import "../../style/style.css";
import { Button, Card, CardContent, Grid, TextField, Typography, Select, InputLabel, MenuItem, FormControl } from '@mui/material';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { fetchCategoryList } from '../../api/CategoryApi'
import { saveGroup } from '../../api/GroupApi'
import { blobFromJson } from '../../utils/Util'
import MainNavbar from "../../components/MainNavbar";
import CategorySelection from "../../components/category/CategorySelection";

function AddGroup() {

    const [file, setFile] = useState();
    const [fileUri, setFileUri] = useState('');
    const [grpName, setGrpName] = useState('');
    const [grpCode, setGrpCode] = useState('');
    const [grpDesc, setGrpDesc] = useState('');
    const [isGrpActive, setIsGrpActive] = useState('0');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categoryOptions, setCategoryOptions] = useState([]);
    const navigate = useNavigate();

    const onSubmit = () => {
        console.log('test API calling');
        const formData = new FormData();
        formData.append("groupImage", file);
        formData.append('group', blobFromJson({
            "name": grpName,
            "description": grpDesc,
            "code": grpCode,
            "active": isGrpActive == "1"
        }));
        saveGroup(selectedCategory, formData, onGroupSaveSuccess, onGroupSaveFailed);
    }

    const onGroupSaveSuccess = (payload) => {
        navigate("/groupList", { replace: true })
    }
    const onGroupSaveFailed = (error) => {
        alert(error.message)
    }

    const selectFile = (event) => {
        setFile(event.target.files[0]);
        setFileUri(URL.createObjectURL(event.target.files[0]));

    }

    const onCategorySelect = (category) => {
        setSelectedCategory(category.id);
    }

    const onCategoryListSuccess = (payload) => {
        setCategoryOptions(payload)
    }
    const onCategoryListFailed = (error) => {
        alert(error.message);
    }
    const onUnthorized = (error) => {
        navigateToLogin();
    }
    useEffect(() => {
        fetchCategoryList(onCategoryListSuccess, onCategoryListFailed);
    }, [])

    const navigateToLogin = () => {
        navigate('/login', { replace: true });
    }

    const handleClick = () => {
        console.log("go back")
        navigate('/groupList', { replace: true });
    };

    return (
        <div>
            <Typography gutterBottom variant="h5" align="center">
                Add Group
            </Typography>
            <MainNavbar />
            <img className='preview-img'
                src={fileUri} />

            <Card style={{ maxWidth: 600, margin: "0 auto", padding: "25px 25px", marginBottom: 32 }}>
                <>

                    <Grid container spacing={4}>
                        <Grid xs={12} item>
                            <Button variant="contained" component="label"
                            >
                                Upload File
                                <input
                                    type="file"
                                    hidden
                                    onChange={selectFile}
                                />
                            </Button>
                        </Grid>
                        <Grid xs={12} item>
                            <TextField label="Name" value={grpName} onChange={e => setGrpName(e.target.value)} placeholder="Enter product Name" variant="outlined" fullWidth required />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField label="Code" value={grpCode} onChange={e => setGrpCode(e.target.value)} placeholder="Enter Code" variant="outlined" fullWidth required />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField label="Description" value={grpDesc} onChange={e => setGrpDesc(e.target.value)} multiline rows={3} placeholder="Enter Description" variant="outlined" fullWidth required />
                        </Grid>

                        <Grid xs={12} align="left" item>
                            <CategorySelection onCategorySelect={category => onCategorySelect(category)} categoryItems={categoryOptions} />

                        </Grid>
                        <Grid xs={12} item align="left">
                            <div value={isGrpActive} onChange={e => setIsGrpActive(e.target.value)} >
                                <Typography variant="contained">Active Status</Typography>
                                <input type="radio" value='1' name="Active" />Yes
                                <input type="radio" value='0' name="Active" /> No
                            </div>
                        </Grid>
                        <Grid xs={6} sx={{ justifyContent: "center" }} item >
                            <Button text-align="center" className="button" type="Cancle" variant="contained" color="error" onClick={(handleClick)} >Cancle</Button>
                        </Grid>
                        <Grid xs={6} item>
                            <Button className="button" type="save" variant="contained" color="primary" onClick={onSubmit}>Save</Button>
                        </Grid>
                    </Grid>

                </>
            </Card>
        </div>
    )
}
export default AddGroup;