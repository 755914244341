import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CategoryIcon from '@mui/icons-material/Category';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import GroupIcon from '@mui/icons-material/Group';

export const mainNavbarItems = [
    {
        id: 0,
        lable: "Marchent",
        icon: <AddBusinessIcon />,
        route: "/marchentList"
    },
    {
        id: 1,
        lable: "Users",
        icon: <GroupIcon />,
        route: "/userList"
    },
    {
        id: 2,
        lable: "Categories",
        icon: <CategoryIcon />,
        route: "/categoryList"
    },
    {
        id: 3,
        lable: "Group",
        icon: <LocalGroceryStoreIcon />,
        route: "/groupList"
    },
    {
        id: 4,
        lable: "Product",
        icon: <ProductionQuantityLimitsIcon />,
        route: "/productList"
    },
    // 
    // {
    //     id: 5,
    //     lable: "Bucket",
    //     icon: <ShoppingBasketSharpIcon />,
    //     route: "/bucket"
    // },
];