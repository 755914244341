import { useEffect, useState } from 'react';
import useViewModel from "./SessionAlertViewModel"
import AlertDialog from '../../../components/alert/AlertDialog';

export default function SessionAlert() {

    const {
        sessionRemaining,
        showModal,
        message,
        disableModal,
        error,
        checkRemainingTime,
        extendPageSession,
        disableModalCheck } = useViewModel();

    useEffect(() => {
        const interval = setInterval(() => {
            checkRemainingTime();
            clearInterval(interval);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });

    return (
        <>
            <AlertDialog open={showModal} title="Extend Session ?" message={message} positiveButton={"Yes"} negativeButton={"No"}
                onNegativeClick={disableModalCheck} onPositiveClick={extendPageSession} />
        </>
    );
};