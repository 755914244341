import { useState } from "react";
import { GetNewTokenUseCase } from "../../../domain/usecase/login/GetNewTokenUseCase";
import { GetRefreshTokenUseCase } from "../../../domain/usecase/login/GetRefreshTokenUseCase";
import { getSessionTimeUseCase } from "../../../domain/usecase/login/GetSessionTimeUseCase";
import { SaveTokenDetailUseCase } from "../../../domain/usecase/login/SaveTokenDetailUseCase";

export default function SessionAlertViewModel() {
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [sessionRemaining, setSessionRemaining] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [disableModal, setDisableModal] = useState(false);

    const checkRemainingTime = () => {
        const sessionTime = getSessionTimeUseCase();
        initMessage(sessionTime)
        setSessionRemaining(sessionTime)
        if (sessionTime == 30 && !showModal && !disableModal) {
            setShowModal(true);
        }

        if (sessionTime <= 0) {
            if (!showModal) setShowModal(true);
        }
    }

    const extendPageSession = async () => {
        const refreshToken = await GetRefreshTokenUseCase()
        if (refreshToken == null) {
            logout()
            return
        }
        const { payload, error } = await GetNewTokenUseCase({ refreshToken })
        setError(error)
        if (payload == null) {
            logout()
            return
        }
        SaveTokenDetailUseCase(payload)
        setShowModal(false);
    };

    const disableModalCheck = () => {
        setDisableModal(true);
        setShowModal(false);
        logout();
    };

    const logout = () => {
        //TODO need to logout the user
    }

    const initMessage = (sessionTime) => {
        if (sessionTime > 0) setMessage(((sessionTime) + (sessionTime > 1 ? " seconds remaining" : "second remaining")))
        else setMessage("Session has been expired do you want to extend");
    }

    return {
        sessionRemaining,
        showModal,
        message,
        disableModal,
        error,
        checkRemainingTime,
        extendPageSession,
        disableModalCheck
    }
}