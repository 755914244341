import React from 'react';
import { Grid } from '@mui/material';
import ProductItem from './ProductItem';


export default function ProductGridList(props) {
    return (
        <Grid container spacing={3}>
            {
                props.products.map(product =>
                (<Grid key={product.id} xs={12} sm={6} md={3} item>
                    < ProductItem product={product} />
                </Grid>))
            }
        </Grid>
    );
}