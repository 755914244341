import React from 'react';
import { Grid } from '@mui/material';
import GroupItem from './GroupItem';


export default function GroupGridList(props) {
    return (
        <Grid container spacing={3}>
            {
                props.groups.map(group =>
                (<Grid key={group.id} xs={12} sm={6} md={3} item>
                    < GroupItem group={group} />
                </Grid>))
            }
        </Grid>
    );
}