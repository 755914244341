const USER_DETAIL_KEY = 'user_detail'

export const getGuid = () => {
    const user = getUserDetail();
    return user == null ? '' : user.id;
}

export const saveUserDetail = (userDetail) => {
    return localStorage.setItem(USER_DETAIL_KEY, JSON.stringify(userDetail));
}
export const getUserDetail = () => {
    return JSON.parse(localStorage.getItem(USER_DETAIL_KEY));
}