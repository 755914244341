import * as React from 'react';
import { Box, Container, Typography } from '@mui/material';
import ShopDetail from '../../components/shop/ShopDetail';
import BusinessDetails from '../../components/business/BusinessDetails';
import PersonalDetails from '../../components/personalDetails/PersonalDetails';
import PersonalKycDetails from '../../components/personalKycDetails/PersonalKycDetails';
import { useState, useEffect } from 'react';
import MainDrawer from '../../components/MainNavbar';
import { useParams } from 'react-router-dom';
import { fetchMerchantDetail, fetchMerchantProfile, updateBusinessDetails, updateMerchantStatus, updatePersonalKycStatus, updateShopDetaiStatus } from '../../api/MerchantApi';
import Loading from '../../components/nb-loading/Loading';

export default function MerchantDetails(props) {
    const [merchentDetail, setMerchentDetail] = useState({});
    const [merchantProfile, setMerchantProfile] = useState({})
    const [address, setAddress] = useState({})
    const [loading, setLoading] = useState(true)
    let { id } = useParams();
    const onMerchatDetailSuccess = (payload) => {
        setMerchentDetail(payload);
    }

    const onProfileSuccess = (payload) => {
        setLoading(false);
        onMerchatDetailSuccess(payload?.merchantDetail);
        setMerchantProfile({
            profile: payload?.profile,
            statusDetails: { status: payload?.merchantDetail?.status, statusDesc: payload?.merchantDetail?.statusDesc }
        });
        setAddress(payload.merchantDetail?.address)
    }
    const onProfileFailed = (error) => {
        setLoading(false);
        console.log(error);
    }
    const onBusinessUpdateClick = (statusDetails) => {
        const request = {
            merchantId: id,
            status: statusDetails.status,
            feedback: statusDetails.statusDesc
        }
        updateBusinessDetails(request, onBusinessUpdated, onUpdateFailed);
    }

    const onBusinessUpdated = (payload) => { fetchProfileDetails() }

    const onUpdateFailed = (error) => { console.log(error.message); }

    const fetchProfileDetails = () => {
        fetchMerchantProfile(id, onProfileSuccess, onProfileFailed);
    }

    const onMerchantStatusUpdateClick = (statusDetails) => {
        const request = {
            merchantId: id,
            status: statusDetails.status,
            feedback: statusDetails.statusDesc
        }
        updateMerchantStatus(request, onMerchantStatusUpdated)
    }
    const onMerchantStatusUpdated = (payload) => {
        fetchProfileDetails();
    }

    const onShopDetailUpdateClick = (statusDetails) => {
        const request = {
            merchantId: id,
            status: statusDetails.status,
            feedback: statusDetails.statusDesc
        }
        updateShopDetaiStatus(request, onUpdateSuccess, onUpdateFailed);
    }

    const onUpdateSuccess = (payload) => {
        fetchProfileDetails();
    }

    const onPersonalKeyUpdateClick = (statusDetails) => {
        const request = {
            merchantId: id,
            status: statusDetails.status,
            feedback: statusDetails.statusDesc
        }
        updatePersonalKycStatus(request, onUpdateSuccess, onUpdateFailed);
    }


    useEffect(() => {
        setLoading(true)
        fetchProfileDetails();
    }, []);
    return (
        <>
            <MainDrawer />
            <Loading loading={loading} mt={12} />
            <Container sx={{ width: "70%", mb: 4 }}>
                <Typography variant='h4' >Merchant Details</Typography>
                <Box sx={{ mt: 4 }}> <PersonalDetails personalDetail={merchantProfile}
                    updateMerchantStatus={(request) => { onMerchantStatusUpdateClick(request) }} /> </Box>

                <Box sx={{ mt: 4 }}> <ShopDetail shopDetail={merchentDetail.shopDetail} address={address}
                    updateShopStatus={(request) => { onShopDetailUpdateClick(request) }} /> </Box>

                <Box sx={{ mt: 4 }}> <BusinessDetails businessDetail={merchentDetail.businessDetail}
                    updateBusinessDetails={(request) => { onBusinessUpdateClick(request) }} /> </Box>

                <Box sx={{ mt: 4 }}> <PersonalKycDetails personalKycDetail={merchentDetail.personalKycDetail}
                    updatePersonalKycStatus={(request) => { onPersonalKeyUpdateClick(request) }} /> </Box>
            </Container>
        </>
    );
}