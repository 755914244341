import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Avatar, Button, CardHeader, Grid } from '@mui/material';
import { MerchantStatusValues } from '../../constants/Constants';
import Dropdown from '../dropdown/Dropdown';
import { useState } from 'react';
import InputAlertDialog from '../alert/InputAlertDialog';

export default function PersonalDetails(props) {
    const personalDetail = props.personalDetail ? props.personalDetail : {}
    const [statusChanged, setStatusChanged] = useState(false);
    const [status, setStatus] = useState();
    const [showDialog, setShowDialog] = useState(false);

    const updateMerchantStatus = (feedback) => {
        props.updateMerchantStatus({ status: status, statusDesc: feedback })
    }
    const onStatusChange = (status) => {
        setStatus(status);
        setStatusChanged(true);
    }

    return (
        <Card sx={{ minWidth: 150 }}>
            <CardHeader
                title="Personal Details"
            />
            <Grid xs={6} item>
                <Box
                    align="left"
                    sx={{
                        display: 'flex',
                        padding: 2
                    }}>

                    <Avatar

                        alt={personalDetail.profile?.firstName}
                        src={personalDetail.profile?.imageUrl}
                        sx={{ width: 100, height: 100, ml: 2 }}
                    />

                    <Box
                        align="left"
                        sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>

                        <Typography>Name- </Typography>
                        <Typography sx={{ mt: 1 }}>Phone Number -</Typography>
                        <Typography sx={{ mt: 1 }}>Email Id - </Typography>
                        <Typography sx={{ mt: 2 }}>Merchant Status -</Typography>
                        <Typography sx={{ mt: 5 }}>Status Description -</Typography>

                    </Box>

                    <Box
                        align="left"
                        sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>

                        <Typography>{personalDetail.profile?.firstName + " " + personalDetail.profile?.lastName}</Typography>
                        <Typography sx={{ mt: 1 }}>{personalDetail.profile?.mobileNo}</Typography>
                        <Typography sx={{ mt: 1 }}>{personalDetail.profile?.emailId}</Typography>
                        <Dropdown mt={3} items={MerchantStatusValues} selectedItem={personalDetail.statusDetails?.status}
                            onSelectItem={(status) => { onStatusChange(status) }} />
                        <Typography sx={{ mt: 2 }}>{personalDetail.statusDetails?.statusDesc}</Typography>
                    </Box>

                </Box>
            </Grid>
            <Grid align="end" sx={{ margin: 2 }} item>
                <Button variant='contained' onClick={() => setShowDialog(true)} disabled={!statusChanged}>Update</Button>
            </Grid>
            <InputAlertDialog open={showDialog} onDialogClose={() => { setShowDialog(false) }} height="150px"
                onPositiveClick={(message) => {
                    setShowDialog(false);
                    updateMerchantStatus(message);
                }} title={"Do you want to update Merchant status to " + status + "?"}
                inputLabel="Status Descritpion" inputPlaceHolder="Enter the Description" />
        </Card>

    );
}