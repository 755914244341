import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, Grid } from '@mui/material';
import ProductDetailDialog from '../productDetail/ProductDetailDailog';
import { useState } from 'react';

export default function ProductItem(props) {
    const [open, setOpen] = useState(false);
    const product = props.product
    return (
        <>
            <Card sx={{ minWidth: 150 }} elevation={2}>
                <CardHeader
                    title={product.name}
                    titleTypographyProps={{ variant: 'h6' }}
                />
                <CardActionArea>
                    <CardMedia
                        component="img"
                        width={200}
                        maxHeight={200}
                        image={product.imageUrl}
                    />
                </CardActionArea>
                <CardContent >
                    <Typography sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3
                    }} variant="body2" color="text.secondary" align='justify'>
                        {product.description}
                    </Typography>
                </CardContent>
                <CardContent>
                    <Grid xs={12} item align="end" >
                        <Button variant='contained' color='primary' onClick={() => setOpen(true)}> Detail</Button>
                    </Grid>
                </CardContent>
            </Card >
            <ProductDetailDialog open={open} productDetail={product} setOpen={() => { setOpen(false) }} >
            </ProductDetailDialog>
        </>
    );
}