import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import "../../style/style.css";
import { useNavigate } from 'react-router-dom';
import { saveCategory } from '../../api/CategoryApi'
import { blobFromJson } from '../../utils/Util'
import MainNavbar from "../../components/MainNavbar";
import { isSessionValid } from '../../storage/AppData';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Loading from '../../components/nb-loading/Loading';

function AddCategory() {
    const [file, setFile] = useState({});
    const [fileUri, setFileUri] = useState('');
    const [catName, setCatName] = useState('');
    const [catCode, setCatCode] = useState('');
    const [catDesc, setCatDesc] = useState('');
    const [isCatActive, setIsCatActive] = useState(false);
    const navigate = useNavigate();

    const selectFile = (event) => {
        console.log(event.target.files);
        setFile(event.target.files[0]);
        setFileUri(URL.createObjectURL(event.target.files[0]));
    }

    const setActive = (event) => {
        console.log(event.target.value)
    }

    const onSubmit = () => {
        const formData = new FormData();
        formData.append("categoryImage", file);
        formData.append('category', blobFromJson({
            "name": catName,
            "code": catCode,
            "description": catDesc,
            "active": isCatActive
        }));
        saveCategory(formData, onCategoryAdded, onCategoryAddFailed)
    }

    const onCancelClick = () => {
        navigate('/categoryList', { replace: true });
    };

    const onCategoryAdded = (response) => {
        navigate('/categoryList', { replace: true });
    }
    const onCategoryAddFailed = (error) => {
        alert(error.message)
    }
    const navigateToLogin = () => {
        navigate('/login', { replace: true });
    }
    useEffect(() => {
        if (!isSessionValid())
            navigateToLogin();

    });



    return (
        <div className='category'>
            <MainNavbar />
            <Typography gutterBottom variant="h5" align="center">
                Add Category
            </Typography>

            <Card style={{ maxWidth: 600, margin: "0 auto", padding: "25px 25px" }}>

                <Grid container spacing={3}>
                    <Grid xs={4} item align="left">
                        <img className='preview-img' src={fileUri} border="1px" float="left" />
                    </Grid>
                    <Grid xs={8} item align="left">
                        <Button variant="contained" component="label"
                        >
                            Upload File
                            <input
                                type="file"
                                hidden
                                onChange={selectFile}
                            />
                        </Button>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField label="Name" value={catName} onChange={e => setCatName(e.target.value)} placeholder="Enter Name" variant="outlined" fullWidth required />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField label="Code" value={catCode} onChange={e => setCatCode(e.target.value)} placeholder="Enter Code" variant="outlined" fullWidth required />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField label="Description" value={catDesc} onChange={e => setCatDesc(e.target.value)} multiline rows={4} placeholder="Enter Description" variant="outlined" fullWidth required />
                    </Grid>
                    <Grid xs={12} item align="left">
                        <FormLabel id="demo-row-radio-buttons-group-label" row>Active</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={e => setIsCatActive(e.target.value)}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />

                        </RadioGroup>
                    </Grid>
                    <Grid xs={8} item align="right" >
                        <Button type="Cancle" variant="contained" color="error" onClick={onCancelClick}>Cancle</Button>
                    </Grid>
                    <Grid xs={4} item align="right">
                        <Button type="Save" variant="contained" color="primary" onClick={onSubmit}>Save</Button>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

export default AddCategory;