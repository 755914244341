import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, CardContent, Grid, TextField, Box } from '@mui/material';
import "../../style/style.css";
import { useNavigate } from 'react-router-dom';
import { saveCategory, updateCategory } from '../../api/CategoryApi'
import { blobFromJson } from '../../utils/Util'
import MainNavbar from "../../components/MainNavbar";
import { isSessionValid } from '../../storage/AppData';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

export default function AddCategory(props) {
    const categoryDetail = props.categoryDetail
    const [file, setFile] = useState({});
    const [fileUri, setFileUri] = useState(categoryDetail?.imageUrl);
    const [catName, setCatName] = useState(categoryDetail?.name);
    const [catCode, setCatCode] = useState(categoryDetail?.code);
    const [catDesc, setCatDesc] = useState(categoryDetail?.description);
    const [isCatActive, setIsCatActive] = useState(categoryDetail?.active);
    const [disabledButton, setDisabledButton] = useState(true)
    const navigate = useNavigate();

    const selectFile = (event) => {
        console.log(event.target.files);
        setFile(event.target.files[0]);
        setFileUri(URL.createObjectURL(event.target.files[0]));
        setDisabledButton(false);
    }

    const setActive = (event) => {
        console.log(event.target.value)
    }

    const onSubmit = () => {
        const formData = new FormData();
        formData.append("categoryImage", file);
        formData.append('category', blobFromJson({
            "id": categoryDetail?.id,
            "name": catName,
            "code": catCode,
            "description": catDesc,
            "active": isCatActive
        }));
        updateCategory(formData, onCategoryAdded, onCategoryAddFailed)
    }

    const onCancelClick = () => {
        navigate('/categoryList', { replace: true });
    };

    const onCategoryAdded = (response) => {
        props.onCategoryUpdate();
    }
    const onCategoryAddFailed = (error) => {
        alert(error.message)
    }
    const navigateToLogin = () => {
        navigate('/login', { replace: true });
    }
    useEffect(() => {
        if (!isSessionValid())
            navigateToLogin();

    });

    return (
        <div className='category'>
            <Box sx={{
                border: '1px black'
            }}>
                <Card style={{ maxWidth: 600, margin: "0 auto", padding: "25px 25px", marginBottom: 32 }}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid xs={12} item align='center'>
                                <Grid>
                                    <img className="preview-img"
                                        src={fileUri}
                                    />
                                </Grid>
                                <Button variant="contained" component="label">
                                    Upload File
                                    <input
                                        align="center"
                                        type="file"
                                        hidden
                                        onChange={selectFile}
                                    />
                                </Button>
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Name" value={catName} onChange={e => { setCatName(e.target.value); setDisabledButton(false) }} placeholder="Enter Name" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Code" value={catCode} onChange={e => setCatCode(e.target.value)} disabled placeholder="Enter Code" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Description" value={catDesc} onChange={e => { setCatDesc(e.target.value); setDisabledButton(false) }} multiline rows={4} placeholder="Enter Description" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item align="left">
                                <FormLabel id="demo-row-radio-buttons-group-label" row>Active</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={e => { setIsCatActive(e.target.value); setDisabledButton(false) }}
                                    value={isCatActive}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />

                                </RadioGroup>
                            </Grid>
                            <Grid xs={12} item align="right">
                                <Button type="Save" variant="contained" color="primary" disabled={disabledButton} onClick={onSubmit}>Update</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </div>
    )
}