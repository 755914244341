import { fetchData, getData } from "./ApiClient";

const USER_PATH = "/admin/api/user/"

export const fetchUserListByStatus = (params, onUserListSuccess, onUserListFailed) => {
    fetchData({ path: USER_PATH, params: params }, onUserListSuccess, onUserListFailed);
};

export const getUserCount = async () => {
    return await (getData({ path: USER_PATH + "/count" }))
}