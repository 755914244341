import "./ApiClient"
import { fetchData, postMultiPartData, updateMultiPartData } from "./ApiClient"

const categoryPath = '/admin/api/product/category'

export const fetchCategoryList = (onCategoryListSuccess, onCategoryListFailed) => {
    return fetchData({ path: categoryPath + '/all' }, onCategoryListSuccess, onCategoryListFailed);
}
export const fecthCategoryDetail = (props, onCategoryDetailSuccess, onCategoryDetailFailed) => {
    return fetchData({ path: categoryPath + props.id }, onCategoryDetailSuccess, onCategoryDetailFailed);
}

export const saveCategory = (body, onCategoryAdded, onCategoryAddFailed) => {
    return postMultiPartData({ path: categoryPath + '/save', body: body }, onCategoryAdded, onCategoryAddFailed)
}

export const updateCategory = (body, onCategoryAdded, onCategoryAddFailed) => {
    return updateMultiPartData({ path: categoryPath + '/update', body: body }, onCategoryAdded, onCategoryAddFailed)
}