import React from "react";
import { Grid } from "@mui/material";
import UserItem from "./UserItem";

export default function UserGridList({ userItems }) {
    return (
        <Grid container spacing={3}>
            {
                userItems.map(user =>
                (<Grid key={user.id} xs={12} sm={6} md={3} item>
                    < UserItem user={user} />
                </Grid>))
            }
        </Grid>
    );
}