import React from 'react';
import MerchantItem from './MerchantItem';
import { Grid } from '@mui/material';

export default function MerchantGridList({ merchantItems }) {
  return (
    <Grid container spacing={3}>
      {
        merchantItems.map(merchant =>
        (<Grid key={merchant.id} xs={12} sm={6} md={3} item>
          < MerchantItem merchant={merchant} />
        </Grid>))
      }
    </Grid>
  );
}
