import * as React from 'react';
import { Alert, Box, Stack } from "@mui/material";
import { useState, useEffect } from 'react';

export default function ErrorAlert(props) {

    var message = props.errorMessage
    const onErrorClose = () => {
        props.onErrorClose()
    }
    return (
        <div>
            <Box hidden={message == null || message.length <= 0} align="center" sx={{ padding: 1 }}>
                <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={() => onErrorClose()}>
                        {message}
                    </Alert>
                </Stack>
            </Box>

        </div>

    )
}