import { useEffect, useState } from "react";
import { Button, Card, CardContent, Grid, TextField, Box, FormControlLabel, RadioGroup, Radio, FormLabel } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { fetchCategoryList } from '../../api/CategoryApi'
import { saveGroup, updateGroup } from '../../api/GroupApi'
import { blobFromJson } from '../../utils/Util'
import CategorySelection from "../category/CategorySelection";

export default function GroupDetail(props) {
    const groupDetail = props.groupDetail
    const [file, setFile] = useState();
    const [fileUri, setFileUri] = useState(groupDetail?.imageUrl);
    const [grpName, setGrpName] = useState(groupDetail?.name);
    const [grpCode, setGrpCode] = useState(groupDetail?.code);
    const [grpDesc, setGrpDesc] = useState(groupDetail?.description);
    const [isGrpActive, setIsGrpActive] = useState(groupDetail?.active);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [disableButton, setDisableButton] = useState(true)
    const navigate = useNavigate();

    const onSubmit = () => {
        console.log('test API calling');
        const formData = new FormData();
        formData.append("groupImage", file);
        formData.append('group', blobFromJson({
            "id": groupDetail?.id,
            "name": grpName,
            "description": grpDesc,
            "code": grpCode,
            "active": isGrpActive,
        }));
        updateGroup(formData, onGroupSaveSuccess, onGroupSaveFailed);
    }

    const onGroupSaveSuccess = (payload) => {
        props.onGroupUpadated();
    }
    const onGroupSaveFailed = (error) => {
        alert(error.message)
    }

    const selectFile = (event) => {
        setFile(event.target.files[0]);
        setFileUri(URL.createObjectURL(event.target.files[0]));
        setDisableButton(false);
    }

    const onCategorySelect = (category) => {
        setSelectedCategory(category.id);
    }

    const onCategoryListSuccess = (payload) => {
        setCategoryOptions(payload)
    }
    const onCategoryListFailed = (error) => {
        alert(error.message);
    }
    const onUnthorized = (error) => {
        navigateToLogin();
    }
    useEffect(() => {
        fetchCategoryList(onCategoryListSuccess, onCategoryListFailed);
    }, [])

    const navigateToLogin = () => {
        navigate('/login', { replace: true });
    }

    const handleClick = () => {
        console.log("go back")
        navigate('/groupList', { replace: true });
    };

    return (
        <div className="group">
            <Box sx={{
                border: '1px black'
            }}>
                <Card style={{ maxWidth: 600, margin: "0 auto", padding: "25px 25px", marginBottom: 32 }}>
                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid xs={12} item align="center">
                                <Grid >
                                    <img className='preview-img'
                                        src={fileUri} />
                                </Grid>
                                <Button variant="contained" component="label"
                                >
                                    Upload File
                                    <input
                                        type="file"
                                        hidden
                                        onChange={selectFile}
                                    />
                                </Button>
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Name" value={grpName} onChange={e => { setGrpName(e.target.value); setDisableButton(false) }} placeholder="Enter product Name" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Code" value={grpCode} onChange={e => setGrpCode(e.target.value)} disabled placeholder="Enter Code" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Description" value={grpDesc} onChange={e => { setGrpDesc(e.target.value); setDisableButton(false) }} multiline rows={3} placeholder="Enter Description" variant="outlined" fullWidth required />
                            </Grid>

                            <Grid xs={12} align="left" hidden={true} item>
                                <CategorySelection onCategorySelect={category => { onCategorySelect(category); setDisableButton(false) }} categoryItems={categoryOptions} />

                            </Grid>
                            <Grid xs={12} item align="left">
                                <FormLabel id="demo-row-radio-buttons-group-label" row>Active</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={e => {
                                        setIsGrpActive(e.target.value); setDisableButton(false)
                                    }}
                                    value={isGrpActive}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />

                                </RadioGroup>
                            </Grid>
                            <Grid xs={12} item align="right">
                                <Button className="button" type="save" variant="contained" color="primary" disabled={disableButton} onClick={onSubmit}>Update</Button>
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
            </Box>
        </div>
    )
}