import { fetchData, getData, postData, updateData } from "./ApiClient"

const MERCHAT_PATH = "/admin/api/merchant"

export const fetchMerchantListByStatus = (params, onMerchatListSuccess, onMerchantListFailed) => {
    fetchData({ path: MERCHAT_PATH + '/by-status', params: params }, onMerchatListSuccess, onMerchantListFailed);
}

export const fetchMerchantDetail = (id, onMerchatDetailSuccess, onMerchatDetailFailed) => {
    fetchData({ path: MERCHAT_PATH + '/' + id }, onMerchatDetailSuccess, onMerchatDetailFailed);
}
export const fetchMerchantProfile = (id, onProfileSuccess, onProfileFailed) => {
    fetchData({ path: "/api/merchant/profile/" + id }, onProfileSuccess, onProfileFailed);
}
export const updateBusinessDetails = (request, onBusinessUpdateSuccess, onBusinessUpdateFailed) => {
    updateData({ path: MERCHAT_PATH + "/gst-status", body: request }, onBusinessUpdateSuccess, onBusinessUpdateFailed);
}

export const updateMerchantStatus = (request, onUpdateSuccess, onUpdateFailed) => {
    updateData({ path: MERCHAT_PATH + "/status", body: request }, onUpdateSuccess, onUpdateFailed);
}

export const updateShopDetaiStatus = (request, onUpdateSuccess, onUpdateFailed) => {
    updateData({ path: MERCHAT_PATH + "/shop-status", body: request }, onUpdateSuccess, onUpdateFailed);
}

export const updatePersonalKycStatus = (request, onUpdateSuccess, onUpdateFailed) => {
    updateData({ path: MERCHAT_PATH + "/pan-status", body: request }, onUpdateSuccess, onUpdateFailed);
}

export const getMerchantCountsByStatus = async () => {
    return await getData({ path: MERCHAT_PATH + "/count" })
}