import { postMultiPartData, updateMultiPartData } from "./ApiClient"
import { fetchData } from "./ApiClient"
const PRODUCT_PATH = '/admin/api/product/item'
export const saveProduct = (groupId, productData, onProductSavedSuccess, onProductSaveFailed) => {
    return postMultiPartData({ path: PRODUCT_PATH + '/save/' + groupId, body: productData }, onProductSavedSuccess, onProductSaveFailed);
}

export const getProductList = (groupCode, onProductListSuccess, onProductListFailed) => {
    return fetchData({ path: PRODUCT_PATH + '/all/' + groupCode }, onProductListSuccess, onProductListFailed)
}

export const updateProduct = (productData, onProductSavedSuccess, onProductSaveFailed) => {
    return updateMultiPartData({ path: PRODUCT_PATH + '/update', body: productData }, onProductSavedSuccess, onProductSaveFailed);
}
