import { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function CategorySelection(props) {
    const [selectedCategory, setSelectedCategory] = useState();
    const onCategorySelect = (e) => {
        const value = e.target.value;
        props.onCategorySelect(value);
        setSelectedCategory(value);
    }
    return (
        <FormControl sx={{ minWidth: 150 }}>
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
                labelId="category-select-label"
                id="category-select"
                value={selectedCategory}
                label="Category"
                onChange={onCategorySelect}
            >
                {props.categoryItems.map(category => (
                    <MenuItem value={category}>{category.name}</MenuItem>))}
            </Select>
        </FormControl>
    );
}