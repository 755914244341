import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { Typography } from '@mui/material';

export default function NbPagination(props) {
    const [page, setPage] = useState(1);
    var totalPage = props.totalPage ? props.totalPage : 1

    const onPageSelect = (page) => {
        setPage(page)
        props.onPageSelect(page)
    }

    return (
        <Stack spacing={3}>
            <Pagination
                count={totalPage}
                color="error"
                defaultPage={page}
                onChange={(event, value) => { onPageSelect(value) }}
            />
        </Stack>
    );
}