import React, { useEffect, useState } from 'react';
import { Button, Grid, Container, Typography, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import { nbContainerStyles } from '../../style/NbMuiStyles';
import MainNavbar from "../../components/MainNavbar";
import MerchantGridList from '../../components/merchant/MarchantGridList';
import { fetchMerchantListByStatus } from "../../api/MerchantApi"
import Loading from '../../components/nb-loading/Loading';
import { MerchantStatusValues } from '../../constants/Constants';
import ErrorAlert from '../../components/alert/ErrorAlert';
import NbPagination from '../../components/pagination/NbPagination';
import { GetMerchantCountUseCase } from '../../domain/usecase/merchant/GetMerchantCountUseCase';
import Dropdown from '../../components/dropdown/Dropdown';


const MarchentList = () => {
  const classes = nbContainerStyles();
  const [merchantItems, setMerchantItems] = useState([]);
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [status, setStatus] = useState(MerchantStatusValues[0]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [countPayload, setCountPayload] = useState(null)

  useEffect(() => {
    setLoading(true)
    fetchMerchantList(status, pageNo);
    fetchMerchantCounts();
  }, []);

  const fetchMerchantList = (status, pageNo) => {
    setLoading(true)
    fetchMerchantListByStatus({ pageNo: pageNo, pageSize: pageSize, status: status }, onMerchantListSucess, onMerchantListFailed);
  }
  const onMerchantListSucess = (items) => {
    setLoading(false)
    setMerchantItems(items)
  }
  const onMerchantListFailed = (error) => {
    setLoading(false)
    setErrorMessage(error.message)
  }
  const onStatusChange = (status) => {
    setLoading(true)
    setStatus(status)
    setPageNo(1)
    fetchMerchantList(status, 1)
    updatePagination(countPayload, status)
  }

  const fetchMerchantCounts = async () => {
    const { payload, error } = await GetMerchantCountUseCase();
    setCountPayload(payload)
    updatePagination(payload, status)
  }
  const updatePagination = (payload, status) => {
    setTotalPage(1)
    if (payload != null) {
      payload.counts.map(item => {
        if (status == item.status) {
          var count = Math.floor(item.count / pageSize) + ((item.count % pageSize == 0) ? 0 : 1)
          setTotalPage(count)
        }
      })
    }
  }

  const onPageSelect = (page) => {
    console.log("select page " + page);
    setPageNo(page)
    fetchMerchantList(status, page)
  }

  return (
    <>
      <Container>
        <MainNavbar />
        <ErrorAlert errorMessage={errorMessage} onErrorClose={() => { setErrorMessage('') }} />

        <Container>
          <Grid>
            <Grid container className={classes.listHeaderContainer}>
              <Grid xs={4} align="start" item >
                <Typography variant="h4">Merchant List</Typography>
              </Grid>
              <Grid xs={8} align="right" item>
                <Grid container alignSelf="right">
                  <Typography sx={{ maxLines: 2, lineClamp: 2 }} variant="h6" align='right'>
                    Select Status
                  </Typography>
                  <Grid xs={2} align="left" item>
                    <Dropdown items={MerchantStatusValues} selectedItem={status}
                      onSelectItem={(status) => onStatusChange(status)} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Loading loading={loading} mt={12} />
            <Grid className={classes.listContainer} >
              <MerchantGridList merchantItems={merchantItems} />
              <Grid xs={12} align="center" item mt={8}>
                <NbPagination totalPage={totalPage} onPageSelect={(page) => onPageSelect(page)} />
              </Grid>
            </Grid>
          </Grid>
        </Container>

      </Container >
    </>
  );
}

export default MarchentList;
