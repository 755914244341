import * as React from 'react';
import Box from '@mui/material/Box';
import { width } from '@mui/system';
import { PaddingRounded } from '@mui/icons-material';
import "../../style/style.css"

export default function NBImage(props) {
    const width = props.width ? props.width : 151;
    const height = props.height ? props.height : 151;
    const borderRadius = props.borderRadius ? props.borderRadius : "16px"

    return (
        < Box style={{ borderRadius: borderRadius }}
            sx={{
                width: width,
                height: height,
                bgcolor: props.bgcolor ? props.bgcolor : 'black',
                ml: props.ml,

            }}>
            <div style={{ borderRadius: borderRadius, height: "100%", width: "100%" }}>
                <img style={{ borderRadius: borderRadius, height: "100%", width: "100%" }}
                    src={props.src}
                    height="100%"
                    width="100%"
                    srcSet={props.srcSet}
                    alt={props.title}
                />
            </div>
        </Box >
    )
}