import { Button } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import { isSessionValid } from '../../storage/AppData';
import { useEffect, useState } from 'react';
import { Box } from "@mui/material"

function Landing() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    const login = isSessionValid();
    if (login) {
      navigate('/dashboard', { replace: true })
    }
    else {
      setIsLoggedIn(login)
    }
  })

  return (
    <>
      <Box sx={{ mt: 22 }}>
        <div className="App">
          <h1>Welcome to NearBy Admin</h1>
          {!isLoggedIn ? (
            <Link className='btn' to={'/login'}>
              <Button variant="contained" color="primary" >Login</Button>
            </Link>) : null
          }
        </div>
      </Box>
    </>
  );
}

export default Landing;
