import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Button, CardHeader, Grid } from '@mui/material';
import NBImage from '../image/NBImage';
import InputAlertDialog from '../alert/InputAlertDialog';
import Dropdown from '../dropdown/Dropdown';
import { useState } from 'react';
import { Pending } from '@mui/icons-material';
import { MerchantDetailStatusValues } from '../../constants/Constants';

export default function BusinessDetails(props) {
    const businessDetail = props.businessDetail ? props.businessDetail : {}
    const [showDialog, setShowDialog] = useState(false);
    const [statusChanged, setStatusChanged] = useState(false);
    const [status, setStatus] = useState();
    const onUpdateClick = (show) => {
        setShowDialog(show);
    }
    const onStatusChange = (status) => {
        setStatus(status);
        setStatusChanged(true);
    }
    const updateBusinessDetails = (message) => {
        props.updateBusinessDetails({ status: status, statusDesc: message });
    }
    return (
        <Card sx={{ minWidth: 150 }}>
            <CardHeader
                title="Business Details"
            />
            <Grid xs={6} item>
                <Box sx={{
                    display: 'flex',
                    padding: 2
                }}>
                    <NBImage title="Business Kyc Details" src={businessDetail.imageUrl} />
                    <Box
                        align="left"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: "left",
                            ml: 3
                        }}>
                        <Typography sx={{ mt: 1 }}>Business Name -</Typography>
                        <Typography sx={{ mt: 1 }}>Business KYC Type -</Typography>
                        <Typography sx={{ mt: 1 }}> Kyc Number -</Typography>
                        <Typography sx={{ mt: 2 }}>Current Status  -</Typography>
                        <Typography sx={{ mt: 5 }}>Status Description -</Typography>

                    </Box>
                    <Box
                        align="left"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: "left",
                            ml: 3
                        }}>
                        <Typography sx={{ mt: 1 }}>{businessDetail.name}</Typography>
                        <Typography sx={{ mt: 1 }}>{businessDetail.kycType}</Typography>
                        <Typography sx={{ mt: 1 }} >{businessDetail.kycNumber}</Typography>
                        <Dropdown mt={3} items={MerchantDetailStatusValues} selectedItem={businessDetail.status ? businessDetail.status : ""}
                            onSelectItem={(status) => { onStatusChange(status) }} />
                        <Typography sx={{ mt: 2 }}>{businessDetail?.description}</Typography>

                    </Box>
                </Box>
            </Grid>
            <Grid align="end" sx={{ margin: 2 }} item>
                <Button variant='contained' onClick={() => onUpdateClick(true)} disabled={!statusChanged}>Update</Button>
            </Grid>
            <InputAlertDialog open={showDialog} onDialogClose={() => { setShowDialog(false) }} height="150px"
                onPositiveClick={(message) => {
                    setShowDialog(false);
                    updateBusinessDetails(message);
                }} title={"Do you want to update business status to " + status + "?"}
                inputLabel="Status Descritpion" inputPlaceHolder="Enter Description" />

        </Card >

    );
}