import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';


export default function AlertDialog(props) {
    const [open, setOpen] = useState(props.open ? props.open : false);

    const handleClose = () => {
        setOpen(false);
        props.onDialogClose(false);
    };

    const onPositiveClick = () => {
        props.onPositiveClick();
    }
    const onNegativeClick = () => {
        props.onNegativeClick();
    }
    const positiveButton = props.positiveButton ? props.positiveButton : "Confirm"
    const negativeButton = props.negativeButton ? props.negativeButton : "Cancel"

    useEffect(() => {
        setOpen(props.open ? props.open : false);
    }, [props.open]);

    return (
        <Box align="center" sx={{ padding: 3 }}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box sx={{ minWidth: "300px" }}>
                    <DialogTitle id="alert-dialog-title">
                        {props.title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.message}
                        </DialogContentText>
                    </DialogContent>
                </Box>
                <DialogActions>
                    <Button onClick={onNegativeClick}>{negativeButton}</Button>
                    <Button onClick={onPositiveClick} autoFocus>{positiveButton}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}