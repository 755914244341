import React, { useState, useEffect } from 'react';
import { Button, Grid, Container, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import "../../style/style.css";
import { makeStyles } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import { getGroupList } from '../../api/GroupApi'
import MainNavbar from "../../components/MainNavbar";
import CategorySelection from '../../components/category/CategorySelection';
import { fetchCategoryList } from '../../api/CategoryApi';
import { getProductList } from '../../api/ProductApi';
import GroupSelection from '../../components/category/GroupSelection';
import ProductGridList from '../../components/product/ProductGridList';
import Loading from '../../components/nb-loading/Loading';


const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24
  },
  topContainer: {
    paddingLeft: 24,
    paddingBottom: 24
  }
}
);

const GroupList = () => {
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [loading, setLoading] = useState(true)
  const classes = useStyles();
  const navigate = useNavigate();


  useEffect(() => {
    setLoading(true)
    fetchCategoryList(onCategoryListSuccess, onCategoryListFailed);
  }, [])

  const onCategoryListSuccess = (payload) => {
    setLoading(false)
    setCategoryOptions(payload)
  }
  const onCategoryListFailed = (error) => {
    setLoading(false)
    alert(error.message);
  }
  const onCategorySelect = (category) => {
    setLoading(true)
    getGroupList(category.code, onGroupListSuccess, onGroupListFailed);
    setSelectedCategory(category.code);
    setProducts([]);
  }

  const onGroupListSuccess = (payload) => {
    setLoading(false)
    console.log(payload);
    setGroupOptions(payload);
  }
  const onGroupListFailed = (error) => {
    setLoading(false)

    alert(error.message);
  }

  const onGroupSelect = (group) => {
    setLoading(true)
    getProductList(group.code, onProductListSuccess, onProductListFailed)
    setSelectedGroup(group.code);
  }

  const onProductListSuccess = (payload) => {
    setLoading(false)
    console.log(payload);
    setProducts(payload);
  }
  const onProductListFailed = (error) => {
    setLoading(false)
    console.log(error.message);
  }
  return (
    <Container>
      <MainNavbar />

      <Container>
        <Grid container className={classes.topContainer}>
          <Grid xs={3} align="start" item>
            <Typography variant="h4"> Product List</Typography>
          </Grid>
          <Grid xs={3} item>
            <CategorySelection onCategorySelect={category => onCategorySelect(category)} categoryItems={categoryOptions} />
          </Grid>
          <Grid xs={3} item>
            <GroupSelection onGroupSelect={group => onGroupSelect(group)} groupItems={groupOptions} />
          </Grid>
          <Grid xs={3} align="end" item>
            <Link className='btn' to={'/addProduct'}>
              <Button variant="contained" color="primary">Add Product</Button>
            </ Link>
          </Grid>
        </Grid>
      </Container>
      <Loading loading={loading} mt={12} />
      <Grid className={classes.gridContainer} >
        <ProductGridList products={products} />
      </Grid>

    </Container>
  );
}

export default GroupList