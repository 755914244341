import "./style/App.css";
import Login from './views/login/Login';
import Dashboard from './views/home/Dashboard';
import AddCategory from './views/category/AddCategory';
import { Routes, Route, } from "react-router-dom";
import AddGroup from './views/group/AddGroup';
import AddProduct from './views/product/AddProduct';
import CategoryList from './views/category/CategoryList';
import MarchentList from './views/merchant/MarchentList';
import GroupList from './views/group/GroupList';
import ProductList from "./views/product/ProductList";
import Landing from "./views/landing/Landing";
import MarchentDetails from "./views/merchant/MarchentDetails";
import UserList from "./views/users/UserList";
import UserDetail from "./views/users/UserDetails";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/productList" element={<ProductList />} />
        <Route path="/groupList" element={<GroupList />} />
        <Route path="/addCategory" element={<AddCategory />} />
        <Route path="/addGroup" element={<AddGroup />} />
        <Route path="/addProduct" element={<AddProduct />} />
        <Route exact path="/categoryList" element={<CategoryList />} />
        <Route exact path="/marchentList" element={<MarchentList />} />
        <Route exact path="/userList" element={<UserList />} />
        <Route exact path="/marchentDetails/:id" element={<MarchentDetails />} />
        <Route exact path="/userDetails" element={<UserDetail />} />
      </Routes>
    </div>
  );
}

export default App;
