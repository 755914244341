import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Grid } from '@mui/material';
import { Link } from "react-router-dom";

export default function MerchantItem({ merchant }) {
    return (
        <Card sx={{ minWidth: 150 }} elevation={2}>

            <CardHeader
                titleTypographyProps={{ variant: 'h6' }}
                title={merchant?.shopDetail?.name}
                subheader={merchant?.status}
                align="start"
            />
            <CardActionArea>
                <CardMedia
                    component="img"
                    width={200}
                    image={merchant?.shopDetail?.imageUrl}
                />
            </CardActionArea>
            <CardContent>
                <Typography sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3
                }} variant="body2" color="text.secondary" align='left'>
                    {merchant?.statusDesc}
                </Typography>
            </CardContent>
            <CardActions>
                <Grid xs={12} align="right" item >
                    <Link className='btn' to={'/marchentDetails/' + merchant?.id}>
                        <Button variant="contained" color="primary">detail</Button>
                    </Link>
                </Grid>

            </CardActions>
        </Card>
    );
}