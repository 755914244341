import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Button, CardHeader, Grid } from '@mui/material';
import NBImage from '../image/NBImage';
import { MerchantDetailStatusValues } from '../../constants/Constants';
import InputAlertDialog from '../alert/InputAlertDialog';
import { useState } from 'react';
import Dropdown from '../dropdown/Dropdown';

export default function PersonalKycDetails(props) {
    const personalKycDetail = props.personalKycDetail ? props.personalKycDetail : {}
    const [statusChanged, setStatusChanged] = useState(false);
    const [status, setStatus] = useState();
    const [showDialog, setShowDialog] = useState(false);

    const updatePersonalKycStatus = (feedback) => {
        props.updatePersonalKycStatus({ status: status, statusDesc: feedback })
    }
    const onStatusChange = (status) => {
        setStatus(status);
        setStatusChanged(true);
    }

    return (
        <Card sx={{ minWidth: 150 }}>
            <CardHeader title="Personal Kyc Details" />
            <Grid xs={6} item>
                <Box
                    sx={{
                        display: 'flex',
                        padding: 2
                    }}>
                    <NBImage title="Personal Kyc Details" src={personalKycDetail.imageUrl} />
                    <Box
                        align="left"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: "left",
                            ml: 3
                        }}>
                        <Typography> Kyc Type- </Typography>
                        <Typography sx={{ mt: 1 }}> KYC Number -</Typography>
                        <Typography sx={{ mt: 2 }}>Current Status-</Typography>
                        <Typography sx={{ mt: 5 }}>Status Description -</Typography>


                    </Box>
                    <Box
                        align="left"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: "left",
                            ml: 3
                        }}>
                        <Typography>{personalKycDetail.kycType}</Typography>
                        <Typography sx={{ mt: 1 }}> {personalKycDetail.kycNumber}</Typography>
                        <Dropdown mt={3} items={MerchantDetailStatusValues} selectedItem={personalKycDetail?.status}
                            onSelectItem={(status) => { onStatusChange(status) }} />
                        <Typography sx={{ mt: 2 }}>{personalKycDetail?.description}</Typography>

                    </Box>
                </Box>
            </Grid>
            <Grid align="end" sx={{ margin: 2 }} item>
                <Button variant='contained' onClick={() => setShowDialog(true)} disabled={!statusChanged}>Update</Button>
            </Grid>
            <InputAlertDialog open={showDialog} onDialogClose={() => { setShowDialog(false) }}
                onPositiveClick={(message) => {
                    setShowDialog(false);
                    updatePersonalKycStatus(message);
                }} title={"Do you want to update PersonalKYC status to " + status + "?"}
                inputLabel="Status Descritpion" inputPlaceHolder="Enter the Description" />
        </Card>
    );
}