import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, Grid } from '@mui/material';
import { useState } from 'react';
import GroupDetailDialog from '../groupDetail/GroupDetailDialog';

export default function GroupItem(props) {
    const [open, setOpen] = useState(false);
    const group = props.group
    const childern = props.childern
    return (
        <>
            <Card sx={{ minWidth: 150 }} elevation={2}>
                <CardHeader
                    title={group.name}
                    titleTypographyProps={{ variant: 'h6' }}
                />
                <CardActionArea>
                    <CardMedia
                        component="img"
                        width={200}
                        maxHeight={200}
                        image={group.imageUrl}
                    />
                </CardActionArea>
                <CardContent>
                    <Typography sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2
                    }} variant="body2" color="text.secondary" align='left'>
                        {group.description}
                    </Typography>
                </CardContent>
                <CardContent>
                    <Grid xs={12} item align="end">
                        <Button variant="contained" color='primary' onClick={() => setOpen(true)}> Detail </Button>
                    </Grid>
                </CardContent>
            </Card>
            <GroupDetailDialog open={open} groupDetail={group} setOpen={() => { setOpen(false) }}>
            </GroupDetailDialog>
        </>
    );
}
