import { Key } from "@mui/icons-material";
import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";



export default function Dropdown(props) {
    const items = props.items ? props.items : [];
    const minWidth = props.minWidth ? props.minWidth : "200px";
    const size = props.size ? props.size : 'small';
    const [selectedItem, setSelectedItem] = useState(props.selectedItem ? props.selectedItem : '');

    const onSelectItem = (e) => {
        setSelectedItem(e.target.value);
        props.onSelectItem(e.target.value);
    }

    useEffect(() => {
        setSelectedItem(props.selectedItem)
    }, [props.selectedItem])
    return (
        <FormControl sx={{ minWidth: minWidth, mt: props.mt, ml: props.ml }} size={size} >
            <Select
                displayEmpty
                value={selectedItem}
                onChange={onSelectItem}
            >
                {items.map(item => (
                    <MenuItem value={item} key={item}>{item}</MenuItem>))}
            </Select>
        </FormControl>
    );
}