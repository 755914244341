import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';


export default function InputAlertDialog(props) {
    const [open, setOpen] = useState(props.open ? props.open : false);
    const [message, setMessage] = useState('');

    const handleClose = () => {
        setOpen(false);
        props.onDialogClose(false);
    };

    const onPositiveClick = () => {
        handleClose();
        props.onPositiveClick(message);
    }
    const onNegativeClick = () => { handleClose(); }
    const possitiveButton = props.possitiveButton ? props.possitiveButton : "Confirm"
    const negativeButton = props.possitiveButton ? props.negativeButton : "Cancel"
    const width = props.width ? props.width : "600px"
    const height = props.height ? props.height : "150px"

    useEffect(() => {
        setOpen(props.open ? props.open : false);
        setMessage('');
    }, [props.open]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box >
                    <DialogTitle id="alert-dialog-title">
                        {props.title}
                    </DialogTitle>
                    <DialogContent>
                        <TextField label={props.inputLabel} value={message} multiline rows={2} autoFocus margin="dense"
                            onChange={e => setMessage(e.target.value)} placeholder={props.inputPlaceHolder} variant="outlined" fullWidth required />
                    </DialogContent>
                </Box>
                <DialogActions>
                    <Button onClick={onNegativeClick}>{negativeButton}</Button>
                    <Button onClick={onPositiveClick} autoFocus>{possitiveButton}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}