import { v4 as uuid } from 'uuid';

const DEVICE_ID = "deviceId";
const LAST_LOGIN_TIME = "lastLoginTime"

export const clearAppData = () => {
    const deviceID = getDeviceId();
    localStorage.clear();
    return localStorage.setItem(DEVICE_ID, deviceID);
}

export const getDeviceId = () => {
    const id = localStorage.getItem(DEVICE_ID);
    if (id === null || id === '') {
        const unique_id = uuid();
        localStorage.setItem(DEVICE_ID, unique_id);
    }
    return localStorage.getItem(DEVICE_ID);
}
export const isSessionValid = () => {
    const time = getSessionRemainSeconds();
    return time > 0;
}

const SESSION_EXPIRY_DURATION = 1800 //in seconds 30 Mins

export const getSessionRemainSeconds = () => {
    const lastLoginTime = localStorage.getItem(LAST_LOGIN_TIME)
    if (lastLoginTime === null || lastLoginTime === '') return false;
    const currentTime = new Date().getTime()
    const remaining = Math.floor(SESSION_EXPIRY_DURATION - (currentTime - Number(lastLoginTime)) / 1000);
    //console.log("Remaining session seconds ", remaining);
    return remaining
}

export const saveLastLoginTime = () => {
    console.log("save last login time");
    localStorage.setItem(LAST_LOGIN_TIME, new Date().getTime().toString());
}
