import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar1 from "../../components/MainNavbar";
import { isSessionValid } from '../../storage/AppData';

function Dashbord() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSessionValid()) {
      navigate('/login', { replace: true })
    }
  });

  return (
    <>
      <Navbar1 />
      <h1>Welcome to NearBy Admin Portal </h1>
    </>
  );
}

export default Dashbord;
