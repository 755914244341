import { useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, TextField, Typography, Select, InputLabel, MenuItem, Alert, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchCategoryList } from '../../api/CategoryApi';
import { getGroupList } from '../../api/GroupApi';
import { blobFromJson } from '../../utils/Util';
import { saveProduct } from '../../api/ProductApi';
import { fetchUnitValues } from '../../api/BucketApi';
import MainNavbar from "../../components/MainNavbar";
import Box from '@mui/system/Box';

function AddProduct() {
    const [file, setFile] = useState({});
    const [fileUri, setFileUri] = useState({});
    const [proName, setProName] = useState('');
    const [proCode, setProCode] = useState('');
    const [proDes, setProDes] = useState('');
    const [proBenifit, setProBenifit] = useState('');
    const [proUse, setProUse] = useState('');
    const [isProActive, setIsProActive] = useState('0');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [groupOptions, setGroupOptions] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState('');
    const [unitValues, setUnitValues] = useState([]);
    const navigate = useNavigate();

    const onSaveProductClick = () => {
        const formData = new FormData();
        formData.append("productImage", file);
        formData.append('product', blobFromJson({
            "name": proName,
            "code": proCode,
            "description": proDes,
            "benefits": proBenifit,
            "storageAndUsage": proUse,
            "active": isProActive == "1",
            "unitBucket": selectedUnit
        }));
        saveProduct(selectedGroup, formData, onProductSavedSuccess, onProductSaveFailed);
    }


    const onProductSavedSuccess = (product) => {
        navigateToProductList();
        console.log(product.id);
    }
    const onProductSaveFailed = (error) => {
        //alert(error.message);
        console.log(error.message);
    }

    const selectFile = (event) => {
        setFile(event.target.files[0]);
        setFileUri(URL.createObjectURL(event.target.files[0]));
    }

    const onCategorySelect = (e) => {
        const cat = e.target.value
        setSelectedCategory(cat);
        getGroupList(cat, onGroupListSuccess, onGroupListFailed);
    }
    const onCategoryListSuccess = (payload) => {
        setCategoryOptions(payload);
    }
    const onCategoryListFailed = (error) => {
        console.log(error.message);
    }

    const onBucketListSuccess = (bucketPayload) => {
        setUnitValues(bucketPayload);
    }
    const onBucketListFailed = (e) => {
        console.log(e.message);
    }

    const onGroupSelect = (e) => {
        setSelectedGroup(e.target.value);
    }

    const onGroupListSuccess = (payload) => {
        setGroupOptions(payload);
    }
    const onGroupListFailed = (error) => {
        setGroupOptions([]);
    }

    const onUnitSelect = (e) => {
        setSelectedUnit(e.target.value);
    }

    const navigateToProductList = () => {
        navigate('/productList', { replace: true });
    };

    useEffect(() => {
        fetchCategoryList(onCategoryListSuccess, onCategoryListFailed);
        fetchUnitValues(onBucketListSuccess, onBucketListFailed);
    }, []);


    return (
        <div className='product'>
            <MainNavbar />
            <Typography gutterBottom variant="h4" align="center">
                Product
            </Typography>
            <img className="preview-img"
                src={fileUri}
            />
            <Box sx={{
                border: '1px black'
            }}>
                <Card style={{ maxWidth: 600, margin: "0 auto", padding: "25px 25px", marginBottom: 32 }}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid xs={12} item>
                                <Button variant="contained" component="label"
                                >
                                    Upload File
                                    <input
                                        type="file"
                                        hidden
                                        onChange={selectFile}
                                    />
                                </Button>
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Name" value={proName} onChange={e => setProName(e.target.value)} placeholder="Product Name" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Code" value={proCode} onChange={e => setProCode(e.target.value)} placeholder="Enter Code" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Description" value={proDes} multiline rows={3} onChange={e => setProDes(e.target.value)} placeholder="Enter Description" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Benifit" value={proBenifit} multiline rows={3} onChange={e => setProBenifit(e.target.value)} placeholder="Enter Benifit" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField label="Store & Usage" value={proUse} multiline rows={3} onChange={e => setProUse(e.target.value)} placeholder="Enter Store & Usage" variant="outlined" fullWidth required />
                            </Grid>
                            <Grid xs={12} align="left" item>
                                <FormControl sx={{ minWidth: 300 }}>
                                    <InputLabel id="category-select-label">Category</InputLabel>
                                    <Select
                                        labelId="category-select-label"
                                        id="category-select"
                                        value={selectedCategory}
                                        label="Category"
                                        onChange={onCategorySelect}
                                    >
                                        {categoryOptions.map(({ code, name }) => (
                                            <MenuItem value={code} key={code}>{name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid xs={12} align="left" item>
                                <FormControl sx={{ minWidth: 300 }}>
                                    <InputLabel id="group-select-label">Group</InputLabel>
                                    <Select
                                        labelId="group-select-label"
                                        id="group-select"
                                        value={selectedGroup}
                                        label="Group"
                                        onChange={onGroupSelect}>
                                        {groupOptions.map(group => (
                                            <MenuItem value={group.id} key={group.id}>{group.name}</MenuItem>))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} align="left" item>
                                <FormControl sx={{ minWidth: 300 }}>
                                    <InputLabel id="unit-select-label">Unit Bucket</InputLabel>
                                    <Select
                                        labelId="unit-select-label"
                                        id="unit-select"
                                        value={selectedUnit}
                                        label="Unit Bucket"
                                        onChange={onUnitSelect}
                                    >
                                        {unitValues.map(unitValue =>
                                            <MenuItem value={unitValue} key={unitValue.id}>{unitValue.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={8} />
                            <Grid xs={12} item align="left">
                                <div value={isProActive} onChange={e => setIsProActive(e.target.value)}>
                                    <Typography variant='contained' >Active Status</Typography>
                                    <input type="radio" value="1" name="Active" />Yes
                                    <input type="radio" value="0" name="Active" /> No
                                </div>
                            </Grid>
                            <Grid xs={8} item align="right">
                                <Button type="cancle" variant='contained' color='error' onClick={navigateToProductList}>Cancle</Button>
                            </Grid>
                            <Grid xs={4} item align="right">
                                <Button type="save" variant="contained" color="primary" onClick={onSaveProductClick}>Save</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </div>
    )
}
export default AddProduct;