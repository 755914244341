import "./ApiClient"
import { fetchData, postMultiPartData, updateMultiPartData } from "./ApiClient"
const GROUP_PATH = '/admin/api/product/group'
export const saveGroup = (catId, groupData, onGropuSavedSuccess, onGroupSaveFailed) => {
    postMultiPartData({ path: GROUP_PATH + '/save/' + catId, body: groupData }, onGropuSavedSuccess, onGroupSaveFailed)
}
export const updateGroup = (groupData, onGropuSavedSuccess, onGroupSaveFailed) => {
    updateMultiPartData({ path: GROUP_PATH + '/update', body: groupData }, onGropuSavedSuccess, onGroupSaveFailed)
}
export const getGroupList = (categoryCode, onGroupListSuccess, onGroupListFailed) => {
    fetchData({ path: GROUP_PATH + '/all/' + categoryCode }, onGroupListSuccess, onGroupListFailed)
}
